
import ContentModuler from "~/components/login/ContentModule";
export default {
  components: {
    ContentModuler
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit('setIsNav', false);
    this.$store.commit('setIsFooter', false);
  },
  destroyed() {
    this.$store.commit('setIsNav', true);
    this.$store.commit('setIsFooter', true)
  }
};
